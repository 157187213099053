import userMutations          from './users'
import internalMutations    from './internal'
import collectionMutations    from './collections'
import jsonMutations    from './json'

export default {
  ...jsonMutations,
  ...collectionMutations,
  ...userMutations,
  ...internalMutations,
}
