import Vue from 'vue'
import dayjs from 'dayjs';
import 'dayjs/locale/da'
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.locale('en')

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    }
  }
})
