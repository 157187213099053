export const COLLECTION_DOCUMENT = 'COLLECTION_DOCUMENT'
export const COLLECTION_DOCUMENTS = 'COLLECTION_DOCUMENTS'
export const JSON_DOCUMENTS = 'JSON_DOCUMENTS'

export default {
  [COLLECTION_DOCUMENT]: (state) => (collectionName, id) => {
    const collection = state[collectionName]
    return collection.find(d => d.id === id)
  },
  [COLLECTION_DOCUMENTS]: (state) => (collectionName) => {
    const collection = state[collectionName]
    return collection
  },
  [JSON_DOCUMENTS]: (state) => (collectionName) => {
    const collection = state[collectionName]
    let documents = Object.values(collection)
      .map(d => {
        return {
          ...JSON.parse(d.content),
          id: d.id
        }
      })
    return documents
  }
}
