import * as actions             from '../../../store/graph/types'
import JsonCollectionMixin      from "./JsonCollectionMixin"
import getVariableType          from "../../../../../lib/getVariableType"
//import DocumentCollectionsMixin from "@/mixins/documentCollections/DocumentCollectionsMixin"

const Mixin = {
  mixins: [
    JsonCollectionMixin,
  ],
  props: {
    documentId: {
      type: String,
      required: true
    },
    objectPath: {
      type: String,
      default: '',
      validator: val => getVariableType(val) === 'String'
    },
  },
  computed: {
    parsed: {
      get() {
        let parsed

        if (this.content) {
          parsed = JSON.parse(this.content)
        }
        return parsed
      },
      set(newValue) {

        this.patchDocument(newValue)

      }
    },
    object: {
      get() {
        let object
        if (this.parsed) {

          object = this.parsed
          if (this.objectPath) {

            const steps = this.objectPath.trim().split('.').map(s => s.trim())

            steps.forEach(name => object = object[name])

          }
        }
        return object
      },
      set(newValue) {

        if (this.parsed !== undefined) {

          newValue = this.mutatedClone(this.objectPath, newValue)

        }

        this.patchDocument(newValue)
      }
    },
  },
  methods: {
    getField(path) {
      let value
      if (this.parsed) {
        value = this.parsed
        path.split('.').forEach(name => value = value[name])
      }
      return value
    },
    loadDocuments() {
      const collectionNotLoaded = this.collectionName || !this.collection || !this.collection.length
      const documentNotLoaded = this.documentId && !this.document
      if (collectionNotLoaded || documentNotLoaded) {
        this.$store.dispatch(actions.LIST_LATEST_DOCUMENT_VERSIONS, {
          collectionName: this.collectionName,
        })
      }
    },
    mutatedClone(attributePath, newValue) {
      const mutable = {...this.parsed}
      let clone = mutable
      let attributeName

      if (attributePath) {

        const names = attributePath.trim()
          .split('.')
          .map(s => s.trim())
          .filter(s => s)

        attributeName = names.splice(names.length - 1, 1)[0]
        names.forEach(name => clone = clone[name])

        if (attributeName) {
          clone[attributeName] = newValue
        }
      }

      return clone
    },
    patchDocument(newValue) {
      this.$store.dispatch(actions.PATCH_VERSION_CONTENT, {
        collectionName: 'versions',
        nodeId: this.version.id,
        attributeName: 'content',
        newValue: JSON.stringify(newValue)
      })
    },
    removeTrailingPeriod(entry) {
      let trimmed = entry
      if (entry) {
        if (trimmed.endsWith('.')) {
          trimmed = trimmed.substr(0, trimmed.length - 1)
        }
      }
      return trimmed
    },
    renderField(reference, alias, overrideValue) {

      const field = this.fields.find(f => f.label === alias || f.name === alias)

      let value = overrideValue ?? reference[field.name]

      if (field.required) value = value || `<span style="background-color: yellow;">"${alias}" is required</span>`

      return value
    },

  },
}

export default Mixin
