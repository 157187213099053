import * as actions  from '../types'
import { sync, act } from '../../lib/APIRequest'

export default {

  [actions.LOGIN]: (context, payload) => sync(context, actions.LOGIN, payload),
  [actions.LOGOUT]: (context, payload) => sync(context, actions.LOGOUT, {...payload, commitOnFailure: true}),
  [actions.REGISTER_USER]: (context, payload) => sync(context, actions.REGISTER_USER, payload),
  [actions.PUT_USER_EMAIL]: (context, payload) => act(context, actions.PUT_USER_EMAIL, payload),
  [actions.REQUEST_USER_PASSPHRASE_RESET]: (context, payload) => sync(context, actions.REQUEST_USER_PASSPHRASE_RESET, payload),
  [actions.PUT_USER_PASSPHRASE]: (context, payload) => sync(context, actions.PUT_USER_PASSPHRASE, payload),
  [actions.DELETE_USER]: (context, payload) => sync(context, actions.DELETE_USER, payload, true),

}
