export function parseAction(action, onMethod, onUrlTemplate, onRequiredParameters) {

  const rxMethod = '(GET|POST|PUT|PATCH|DELETE)'
  const rxParams = '(?:{([^}]*)})'
  const rxUrl = '(\\S+)'
  const rx = new RegExp(`^${rxMethod}\\s+${rxUrl}(?:\\s+${rxParams})?$`, 'i')
  const details = action.match(rx)

  if (!details) throwMalformedActionError(action)

  onMethod(details[1].toLowerCase())

  onUrlTemplate(details[2])

  if (details[3]) {
    onRequiredParameters(
      details[3]
        .split(',')
        .map(name => name.trim())
    )
  }
}

function throwMalformedActionError(action) {
  throw `malformed action "${action}". Actions must be of the format "$method $urlWithEmbeddedVariableSlots" or "$method {$required, $param, $names} $urlWithEmbeddedVariableSlots"`
}
