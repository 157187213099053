import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/pages/Home.vue'
import Hope from '../components/pages/Hope.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'HOME',
    inMenu: true,
    component: Hope
  },
  {
    path: '/old_home',
    name: 'OLD_HOME',
    inMenu: false,
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    inMenu: true,
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/About.vue')
  },
  {
    path: '/news',
    name: 'News',
    inMenu: true,
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/News.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    inMenu:true,
    component: () => import('../components/pages/Reports.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    inMenu: true,
    beforeEnter() {location.href='https://hope-project.dk/dashboard/'}
  },
  {
    path: '/publications',
    name: 'Publications',
    inMenu: true,
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/Publications.vue')
  },
  {
    path: '/coronaCrisisInDK',
    name: 'CoronaCrisisInDK',
    component: () => import('../components/pages/CoronaCrisisInDK.vue')
  },
  {
    path: '/documents/',
    name: 'documentCollections',
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/Collections.vue'),
  },
  {
    path: '/documents/:collectionName',
    name: 'collectionEditor',
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/Collection.vue'),
    props: true
  },
  {
    path: '/documents/:collectionName/:documentName',
    name: 'documentEditor',
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/Document.vue'),
    props: true
  },
  { // content repository document (.md)
    path: '/document/:directory/:filename',
    name: 'document',
    component: () => import/* webpackChunkName: "pages" */ ('../components/pages/RepoDocument.vue'),
    props: true
  },

  /* Reports: version controlled document collections which only show their latest release, but link to predecessors */
  {
    /*
        List of report collections for creating new report collections
     */
    path: '/:collectionName(rapporter|reports)',
    name: 'reportCollections',
    component: () => import('../components/pages/Reports.vue'),
    props: true
  },
  {
    /*
      Most recent report document. Edit document, delete document
     */
    path: '/:collectionName(rapporter|reports)/:documentName',
    name: 'latestReport',
    component: () => import(/* webpackChunkName: "pages" */ '../components/DocumentCollection/reports/Report.vue'),
    props: true
  },
  {
    /*
      Permalink to specific release.
     */
    path: '/:collectionName(rapporter|reports)/:documentName/versions/:versionAlias',
    name: 'reportVersion',
    component: () => import(/* webpackChunkName: "pages" */ '../components/DocumentCollection/reports/Report.vue'),
    props: true
  },
  {
    path: '/bss',
    name: 'BSS',
    beforeEnter() {location.href='https://bss.au.dk/en/'}
  },
  {
    path: '/ps',
    name: 'PS',
    beforeEnter() {location.href='https://ps.au.dk/en/'}
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "pages" */ '../components/pages/notFound.vue')
  }
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}

const router = new VueRouter({
  //mode: 'history',
  base: '/',
  routes,
})

export default router
