/*
  Todo: USER ACTIONS
 */

export const REGISTER_USER =                  "POST   /users                    {email, passphrase}"
export const LOGIN =                          "POST   /users/login              {email, passphrase}"
export const LOGOUT =                         "POST   /users/logout             {email, APIKey}"
export const PUT_USER_EMAIL =                 "PUT    /users/:email/email       {APIKey, newEmail}"
export const REQUEST_USER_PASSPHRASE_RESET =  "POST   /users/:email/email       {}"
export const PUT_USER_PASSPHRASE =            "PUT    /users/:email/passphrase  {APIKey, newPassphrase}"
export const DELETE_USER =                    "DELETE /users/:email             {APIKey}"

/*
  DOCUMENT COLLECTION ACTIONS
 */

export const LIST_COLLECTIONS =               "GET    :apiPath/collections/                                         {}"
export const CREATE_DOCUMENT_COLLECTION =     "POST   :apiPath/collections/:collectionName                          {email, APIKey}"
export const DELETE_COLLECTION =              "DELETE :apiPath/collections/:collectionName                          {email, APIKey}"
export const LIST_LATEST_DOCUMENT_VERSIONS =  "GET    :apiPath/collections/:collectionName/documents                {}"

/*
  COLLECTION DOCUMENT ACTIONS
 */

export const CREATE_DOCUMENT =                "POST   :apiPath/collections/:collectionName/documents                      {email, APIKey, name, versionName, type, content}"
export const GET_DOCUMENT_BY_NAMES =          "GET    :apiPath/collections/:collectionName/documents/byName/:documentName {}"

export const ENSURE_JSON_DOCUMENT_EXISTS =    "PATCH  :apiPath/documents/:documentId                                      {email, APIKey, index, json, componentName, listComponentName}"
export const PATCH_JSON_DOCUMENT =            "PUT    :apiPath/documents/:documentId                                      {email, APIKey, json}"

export const PUT_DOCUMENT_SERIES_SEQUENCE =   "PUT    :apiPath/collections/:collectionName                                {email, APIKey, documentIdSequence}"

export const GET_DOCUMENT_BY_ID =             "GET    :apiPath/documents/:documentId                                      {}"
export const DELETE_DOCUMENT =                "DELETE :apiPath/documents/:documentId                                      {email, APIKey}"

/*
  REPORT ACTIONS (VERSION CONTROLLED)
 */

export const LIST_DOCUMENT_VERSIONS =           "GET    :apiPath/documents/:documentId/versions                           {}"
export const CREATE_NEW_DOCUMENT_VERSION =      "POST   :apiPath/versions/:versionId                                      {email, APIKey, name}"
export const GET_DOCUMENT_VERSION =             "GET    :apiPath/documents/:documentId/versions/:versionId                {}"
export const GET_VERSION =                      "GET    :apiPath/versions/:versionId                                      {}"

export const PATCH_VERSION_CONTENT =            "PATCH  :apiPath/versions/:nodeId/content                                 {email, APIKey, newValue}"
export const DELETE_VERSION =                   "DELETE :apiPath/versions/:versionId                                      {email, APIKey}"

/*
  INTERNAL
 */

export const CREATE_PRISTINE_STATE =  'CREATE_PRISTINE_STATE'
export const RESTORE_PRISTINE_STATE = 'RESTORE_PRISTINE_STATE'
export const DELETE_PRISTINE_STATE =  'DELETE_PRISTINE_STATE'

export const SET_ACTION_REQUEST =     'SET_ACTION_REQUEST'
export const RESET_ACTION_REQUEST =   'RESET_ACTION_REQUEST'

/*
  TODO: page load strategy:
  app.vue: get collection by name: pageTexts
  vDocument: get document version: id, 'latest'
 */
