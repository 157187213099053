import day from "dayjs"

export const getDefaultPreprintReference = () => {
  return {
    "context": "articleInPrePrint",
    "language": "en",
    "contextName" : "PsyArXiv Preprints",
    "authors": [],
    "publicationYear": day().get('year'),
    "publicationMonth": day().get('month'),
    "publicationDay": day().get('day'),
    "publicationTitle": "",
    "doiUrl": ""
  }
}
export const getDefaultWWebReference = () => {
  return {
    "context": "webPage",
    "language": "da",
    "contextName" : "",
    "authors": [],
    "magazineTitle": "",
    "url": "",
    "publicationYear": day().get('year'),
    "publicationMonth": day().get('month'),
    "publicationDay": day().get('day'),
    "titleOfPage": "",
    "accessYear": day().get('year'),
    "accessMonth": day().get('month'),
    "accessDay": day().get('day'),
  }
}
