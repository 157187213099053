import * as actions    from '../types'
import { act, sync }                   from '../../lib/APIRequest'

export default {

  [actions.LIST_COLLECTIONS]:             (context, payload) => sync(context, actions.LIST_COLLECTIONS, payload),
  [actions.LIST_LATEST_DOCUMENT_VERSIONS]:    (context, payload) => sync(context, actions.LIST_LATEST_DOCUMENT_VERSIONS, payload),
  [actions.LIST_DOCUMENT_VERSIONS]:       (context, payload) => sync(context, actions.LIST_DOCUMENT_VERSIONS, payload),

  [actions.CREATE_DOCUMENT_COLLECTION]:   (context, payload) => sync(context, actions.CREATE_DOCUMENT_COLLECTION, payload),
  [actions.CREATE_DOCUMENT]:              (context, payload) => sync(context, actions.CREATE_DOCUMENT, payload),
  [actions.CREATE_NEW_DOCUMENT_VERSION]:  (context, payload) => sync(context, actions.CREATE_NEW_DOCUMENT_VERSION, payload),

  [actions.GET_DOCUMENT_BY_NAMES]:        (context, payload) => sync(context, actions.GET_DOCUMENT_BY_NAMES, payload),
  [actions.GET_DOCUMENT_VERSION]:         (context, payload) => sync(context, actions.GET_DOCUMENT_VERSION, payload),
  [actions.GET_VERSION]:                  (context, payload) => sync(context, actions.GET_VERSION, payload),

  [actions.PATCH_VERSION_CONTENT]:        (context, patch)   => act(context, actions.PATCH_VERSION_CONTENT, patch),

  [actions.PUT_DOCUMENT_SERIES_SEQUENCE]: (context, patch)   => sync(context, actions.PUT_DOCUMENT_SERIES_SEQUENCE, patch),

  [actions.DELETE_COLLECTION]:            (context, payload) => sync(context, actions.DELETE_COLLECTION, payload),
  [actions.DELETE_DOCUMENT]:              (context, payload) => sync(context, actions.DELETE_DOCUMENT, payload),
  [actions.DELETE_VERSION]:               (context, payload) => sync(context, actions.DELETE_VERSION, payload),

}
