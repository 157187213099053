import * as actions    from '../../../store/graph/types'
import CollectionMixin from "../CollectionMixin"

const Mixin = {
  mixins: [
    CollectionMixin,
  ],
  props: {
    documentId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'md',
      validator: (val) => val === 'md'
    },
  },
  data() {
    return {
      type: 'md',
    }
  },
  computed: {
    defaultContent: {
      get() {
        return `# New Document
    
no content yet`
      }
    },
    document: {
      get() {
        return this.$store.state[this.collectionName][this.documentId]
      }
    },
    md: {
      get() {
        let md
        if (this.document) {

          md = this.document.md

        }
        return md
      },
      set(newValue) {

        this.patchDocument(newValue)

      }
    }
  },
  methods: {
    createMarkdownDocument(event, payload) {
      if (payload === undefined) payload = {}
      if (payload.index === undefined) payload.index = Object.keys(this.collection ?? {}).length
      payload.collectionName = payload.collectionName || this.collectionName
      payload.versionName = payload.versionName || this.$date().format('DD-MM-YYYY')
      payload.type = 'md'
      payload.content = payload.content || this.defaultContent
      payload.documentId = payload.documentId || this.documentId

      return this.$store.dispatch(actions.CREATE_DOCUMENT, payload)
    },
    loadDocuments() {
      const collectionNotLoaded = this.collectionName || !this.collection || !this.collection.length
      const documentNotLoaded = this.documentId && !this.document
      if (collectionNotLoaded || documentNotLoaded) {
        this.$store.dispatch(actions.LIST_LATEST_DOCUMENT_VERSIONS, {
          collectionName: this.collectionName,
          type: this.type
        })
      }
    },
    patchDocument(newValue) {
      this.$store.dispatch(actions.PATCH_VERSION_CONTENT, {
        versionId: this.versionId,
        attributeName: 'md',
        newValue
      })
    },
  }
}

export default Mixin
