const path = require('path')
const nconf = require('../config')

const publicPaths = {
  testing: '',
  production: nconf.get('siteUrl'),
  development: ''
}

module.exports = {
  devServer: {
    proxy: nconf.get('developmentUrl'),
    https: false
  },
  configureWebpack: {
    optimization: {
      splitChunks: {
        minSize: 10000,
        maxSize: 250000,
      }
    },
    resolve: {
      alias : {
        "icons": path.resolve(__dirname, "node_modules/vue-material-design-icons")
      },
      extensions: [
        ".vue"
      ]
    }
  },
  runtimeCompiler: true,
  transpileDependencies: [
    // node_module projects to include
  ],
  publicPath: publicPaths[process.env.NODE_ENV || 'production'],
  crossorigin: "use-credentials"
}
