export function maskSensitiveData(axiosRequestConfig, sensitiveNames) {
  /*
      used to mask sensitive values in fx. error logs
   */
  if (sensitiveNames === undefined) sensitiveNames = ['passphrase', 'password']

  if (axiosRequestConfig.data) {

    const maskedNames = sensitiveNames.filter(e => axiosRequestConfig.data[e] !== undefined)
    if (maskedNames.length) {
      maskedNames.forEach(name => delete axiosRequestConfig.data[name])
      console.log('not showing:', maskedNames)
    }

  }

  return {
    ...axiosRequestConfig
  }
}
