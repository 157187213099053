import { parseAction }   from './parseAction'
import { getRequestUrl } from "./getRequestUrl"

let headerVariableNames = []
export function getActionRequestObject(action, data) {
  let request = {}

  if (!data.type) {
    if (data.json) {
      data.type = 'json'
    } else if (data.md) {
      data.type = 'md'
    } else if (data.csv) {
      data.type = 'csv'
    }
  }

  headerVariableNames = []

  try {

    request.headers = getHeader(data)

    parseAction(
      action,
      (method) => request.method = method,
      (template) => request.url = getRequestUrl(template, data),
      (requiredParams) => request.data = pickRequiredParameters(requiredParams, data)
    )

  } catch (err) {

    throw `getActionRequestObject.${err}
action: "${action}"`
  }

  return request
}

export function getHeader(data) {

  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XmlHttpRequest',
  }

  if (data.oneTimePass) {

    headers.Authorization = data.oneTimePass
    headerVariableNames.push('oneTimePass')
    delete data.oneTimePass

  } else if (data.APIKey) {

    headers.Authorization = data.APIKey
    headerVariableNames.push('APIKey')
    delete data.APIKey

  }

  return headers
}

function pickRequiredParameters(requiredNames, data) {
  let requiredParameters = {}

  if (requiredNames && requiredNames.length) {
    requiredNames.forEach(name => {
      const isOptional = name[name.length -1] === '?'
      if (isOptional) name = name.substring(0, name.length -1)

      const isUndefined = data[name] === undefined && headerVariableNames.indexOf(name) < 0
      if (!isOptional && isUndefined) {
        throwMissingParameterException(name)
      }

      requiredParameters[name] = data[name]
    })
  }
  return requiredParameters
}

function throwMissingParameterException(name) {
  console.warn(`parameter "${name}" is missing.`)

  throw `pickRequiredParameters ERROR 
parameter "${name}" is required.`
}
