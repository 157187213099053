
const defaults = {
  cooloffMs: 10000,
  timestampResolutionMs: 200 // clustering improves performance (memory)
}

function getTimeoutTimestampe() {
  const timestamp = Date.now() + defaults.cooloffMs
  const clusteredTimestamp = Math.round(timestamp / defaults.timestampResolutionMs) * defaults.timestampResolutionMs
  return clusteredTimestamp
}

function hashString(string) {
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash  = ((hash << 5) - hash) + string.charCodeAt(i);
    hash |= 0;
  }
  return hash
}


function HashedTimeouts(cooloffSeconds) {
  if (cooloffSeconds === undefined) {
    defaults.cooloffSeconds = 10
  }
  const timeouts = {}

  return  {
    addTimeout(uniqueString, onTimeout) {
      if (!onTimeout) onTimeout = this.clearTimeout

      const timeoutId = hashString(uniqueString)

      if (!this.isHot(timeoutId)) {

        timeouts[timeoutId] = window.setTimeout(onTimeout, getTimeoutTimestampe())

      }
    },
    cancelTimeout(uniqueString) {
      const timeoutId = hashString(uniqueString)
      window.clearTimeout(timeouts[timeoutId])
      delete timeouts[timeoutId]
    },
    isHot(uniqueString) {
      const timeoutId = hashString(uniqueString)
      return !!timeouts[timeoutId]
    },
    isCool(uniqueString) {
      return !this.isHot(uniqueString)
    },
  }
}

export default new HashedTimeouts()
