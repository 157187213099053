const Mixin =  {
  props: {
    timeoutMS: {
      type: Number,
      default: 20000,
      validator(val) {
        return val > 5000
      }
    }
  },
  data() {
    return {
      mixinTimeout: null,
      countdownTimeout: null,
      secondsRemaining: null
    }
  },
  methods: {
    setTimeout(callback) {
      if (this.mixinTimeout) clearTimeout(this.mixinTimeout)
      this.mixinTimeout = setTimeout(() => callback(), this.timeoutMS)
      this.secondsRemaining = this.timeoutMS / 1000
      this.setCounterTimeout()
    },
    setCounterTimeout() {
      if (this.countdownTimeout) clearTimeout(this.countdownTimeout)
      this.countdownTimeout = setTimeout(() => {
        this.secondsRemaining -= 1
        if (this.secondsRemaining > 1) this.setCounterTimeout()
      }, 1000)
    },
    cancelTimeout() {
      clearTimeout(this.mixinTimeout)
      this.mixinTimeout = null
    }
  }
}

export default Mixin
