import { set }                                      from "@/store/graph/mutations/helpers/storeMutations"
import {
  CREATE_PRISTINE_STATE,
  RESTORE_PRISTINE_STATE,
  DELETE_PRISTINE_STATE,
  RESET_ACTION_REQUEST,
  SET_ACTION_REQUEST
} from "@/store/graph/types"

export default {
  [CREATE_PRISTINE_STATE](state, patch) {

    const pristineValue = state[patch.collectionName][patch.documentId][patch.type]
    set(state, `${patch.collectionName}.${patch.documentId}.${patch.type}`, pristineValue)

  },
  [RESTORE_PRISTINE_STATE](state, patch) {

    const reference = `${patch.collectionName}.${patch.documentId}.${patch.type}`

    const pristineValue = state.pristine[reference]
    set(state, reference, pristineValue)

    delete state.pristine[reference]

  },
  [DELETE_PRISTINE_STATE](state, patch) {

    delete state.pristine[`${patch.collectionName}.${patch.documentId}.${patch.type}`]

  },
  [SET_ACTION_REQUEST]: function (state, action) {

    state.requestedActions[action] = true

  },
  [RESET_ACTION_REQUEST]: function (state, action) {

    delete state.requestedActions[action]

  },
}
