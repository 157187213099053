import * as actions from "../types"
import { set }      from "@/store/graph/mutations/helpers/storeMutations"

export default {
  [actions.LOGIN]: function(state, action) {
    const user = action.response
    if (user) {
      set(state, 'user', user)
    } else {
      state.user = null
    }
  },
  [actions.LOGOUT]: function(state) {
    state.user = null
  },
  [actions.REGISTER_USER]: function(state, action) {
    const user = action.response
    set(state, 'user', user)
  },
  [actions.PUT_USER_EMAIL]: function(state, action) {
    const user = action.response
    set(state, 'user', user)
  },
  [actions.DELETE_USER]: function(state) {
    state.user = null
  }
}

