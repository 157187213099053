import {
  CREATE_DOCUMENT,
  GET_VERSION,
  GET_DOCUMENT_BY_ID,
  GET_DOCUMENT_BY_NAMES,
  LIST_COLLECTIONS,
  LIST_LATEST_DOCUMENT_VERSIONS,
  LIST_DOCUMENT_VERSIONS,
  PATCH_VERSION_CONTENT,
  DELETE_DOCUMENT,
  DELETE_VERSION, CREATE_NEW_DOCUMENT_VERSION,
}                                               from "@/store/graph/types"
import {
  byAscendingIndex,
  byAscendingVersion,
  byDescendingIndex,
  byDescendingVersion
} from "@/components/DocumentCollection/lib/sortFunctions"

export default {
  props: {
    collectionName: {
      type: String
    },
    documentId: {
      type: String
    },
    documentName: {
      type: String
    },
    versionId: {
      type: String
    },
    versionAlias: {
      type: String,
      default: 'latest'
    },
    preloadCollections: {
      type: Array,
      default: ['pageTexts']
    }
  },
  data() {
    return {
      reservedVersionNames: ['latest'],
    }
  },
  computed: {
    collection() {
      let collection
      if (this.collectionName) {
        collection = this.$store.state.collections[this.collectionName]
      }
      return collection
    },
    content: {
      get() {
        return this.version?.content
      }
    },
    document() {
      let document = this.$store.state.documents?.[this.documentId]
      if (!document && this.documentName) {
        document = this.getDocumentByName(this.documentName)
      }
      return document
    },
    documents() {
      return Object.values(this.$store.state.documents).filter(doc => doc.collectionName === this.collectionName)
    },
    documentNames() { return this.documents.map(d => d.name) },
    latestDocumentVersions() {

      let versions = []
      const collection = this.getDocumentsByCollectionName(this.collectionName)

      Object.values(collection)
        .forEach(doc => {

          let latestVersion = Object.values(this.$store.state.versions)
            .filter(v => v.documentId === doc.id)
            .sort((a, b) => a.version - b.version)

          if (latestVersion && latestVersion.length) {
            latestVersion = latestVersion[0]
          }

          versions = versions.concat(latestVersion)

        })

      return versions

    },
    parsed: {
      get() {
        let parsed
        if (this.version && this.document?.type === 'json') {
          parsed = JSON.parse(this.content)
        }
        return parsed
      }
    },
    version() {
      let version
      if (this.versions) {
        version = this.getVersionByAlias(this.versionAlias)
      }
      return version
    },
    versions() {
      let versions = []
      const document = this.document
      const documentId = document?.id || this.documentId
      if (documentId) {
        versions = this.getVersionsByDocumentId(documentId)
      }
      return versions
    },
    versionNames() {
      return this.versions.map(v => v.name)
    },
    sortedDocuments: {
      get() {
        let documents = []

        if (this.documents) {
          documents = Object.values(this.documents)
          documents = documents.sort(byAscendingIndex)
        }

        return documents
      }
    },
    sortedVersions: {
      get() {
        let versions = []

        if (this.documents) {
          if (this.document) {
            versions = Object.values(this.versions)
            versions = versions.sort(byAscendingVersion)
          } else {
            versions = this.latestDocumentVersions
          }
        }

        return versions
      }
    },
    reverseSortedDocuments: {
      get() {
        let documents = []

        if (this.documents) {
          documents = Object.values(this.documents)
          documents = documents.sort(byDescendingIndex)
        }

        return documents
      }
    },
    reverseSortedVersions: {
      get() {
        let versions = []

        if (this.versions) {
          versions = Object.values(this.versions)
          versions = versions.sort(byDescendingVersion)
        }

        return versions
      }
    },
  },
  methods: {
    createDocument(payload) {
      if (payload === undefined) payload = {}
      payload.name = payload.name || 'New Document'
      payload.versionName = payload.versionName || this.$date().format('DD-MM-YYYY')

      if (payload.versionName)
      return this.$store.dispatch(CREATE_DOCUMENT, {
        collectionName: this.collectionName,
        versionName: this.versionName,
        type: 'md',
        content: this.defaultContent || '',
        ...payload,
      })
    },
    createVersion(payload) {
      if (!payload.name) throw 'version.name is required'
      this.$store.dispatch(CREATE_NEW_DOCUMENT_VERSION, {
        ...payload,
        documentId: this.version.documentId,
        versionId: this.version.id,
      })
        .then(() => {
          this.versionAlias = 'latest'
        })
    },
    async deleteDocument() {
      try {
        await this.$store.dispatch(DELETE_DOCUMENT, {
          documentId: this.document.id
        })
      } catch(err) {
        console.warn('document.id',this.document.id)
        console.error('could not delete document', err)
      }
    },
    async deleteVersion() {
      try {
        await this.$store.dispatch(DELETE_VERSION, {
          versionId: this.version.id
        })
      } catch (err) {
        console.warn('version.id',this.version.id)
        console.error('could not delete version', err)
      }
    },
    getDocumentByName(documentName) {
      return Object.values(this.$store.state.documents)
        .find(doc => doc.name === documentName)
    },
    getDocumentsByCollectionName(collectionName) {
      return Object.values(this.$store.state.documents).filter(doc => doc.collectionName === collectionName)
    },
    getVersionByAlias(alias) {
      let version
      if (alias === 'latest') {
        let versions = this.versions.sort(byDescendingVersion)
        if (versions.length) {
          version = versions[0]
        }
      } else {
        version = this.versions.find(v => v.name === alias )
      }

      return version
    },
    getLatestContentByDocumentId(documentId) {
      let versions = this.getVersionsByDocumentId(documentId) ?? []
      versions = versions.sort(byDescendingVersion)
      return versions?.[0].content
    },
    getVersionsByDocumentId(documentId) {
      let versions = []
      const document = this.$store.state.documents[documentId]
      if (document) {
        versions = Object.values(this.$store.state.versions).filter(version => version.documentId === documentId)
      }
      return versions
    },
    loadCollection() {
      if (this.collectionName) {
        this.loadLatestDocumentVersions()
      }
    },
    loadCollections() {
      this.$store.dispatch(LIST_COLLECTIONS)
    },
    loadLatestDocumentVersions(collectionName) {
      collectionName = collectionName ?? this.collectionName
      this.$store.dispatch(LIST_LATEST_DOCUMENT_VERSIONS, {
        collectionName
      })
    },
    loadAllDocumentVersions({documentName, documentId}) {
      const loadedDocument = this.getDocumentByName(documentName)
      if (!loadedDocument) {
        documentId = documentId ?? loadedDocument.id
        if (!documentId) throw "cannot determine document id from names. document needs to be loaded before listing versions"
        this.$store.dispatch(LIST_DOCUMENT_VERSIONS, {
          documentId: documentId
        })
      }
    },
    loadDocument({collectionName, documentId, documentName, versionId, refresh}) {
      collectionName = collectionName ?? this.collectionName
      documentName = documentName ?? this.documentName
      documentId = documentId ?? this.documentId
      versionId = versionId ?? this.versionId
      refresh = refresh || false

      if (versionId) {
        this.loadVersion(versionId, refresh)
      } else if (documentId) {
        this.loadDocumentById({documentId, refresh})
      } else if (collectionName && documentName) {
        this.loadDocumentByNames({collectionName, documentName, refresh})
      }
    },
    loadDocumentById({documentId, refresh}) {
      const loadedVersion = Object.values(this.$store.state.versions).find(version => version.documentId === documentId)
      if (!loadedVersion || refresh) {
        this.$store.dispatch(GET_DOCUMENT_BY_ID, {
          documentId: documentId,
        })
      }
    },
    loadDocumentByNames({collectionName, documentName, refresh}) {
      collectionName = collectionName ?? this.collectionName
      documentName = documentName ?? this.documentName
      const loadedVersion = Object.values(this.$store.state.versions)
        .find(version => version.collectionName === collectionName && version.documentName === documentName)
      if (refresh || !loadedVersion) {
        this.$store.dispatch(GET_DOCUMENT_BY_NAMES, {
          collectionName: collectionName,
          documentName: documentName,
        })
      }
    },
    loadVersion(versionId, refresh) {
      const loadedVersion = this.$store.state.versions[versionId]
      if (refresh || !loadedVersion) {
        this.$store.dispatch(GET_VERSION, {
          versionId: versionId ?? 'latest'
        })
      }
    },
    patchContent(newValue) {
      if (this.document.type === 'json') {
        newValue = JSON.stringify(newValue)
      }
      this.$store.dispatch(PATCH_VERSION_CONTENT, {
        collectionName: 'versions',
        nodeId: this.version.id,
        attributeName: 'content',
        newValue
      })
    },
  }
}
