import vueConf from "../../../../vue.config"
import nconf from "../../../../../config"

export function getRequestUrl(template, data) {

  let url = getServerBaseUrl()

  try {

    url += fillOutUrlTemplate(template, data)

  } catch (err) {

    console.log('data', data)

    throw `getActionRequestObject.${err} 
action: "${template}"`

  }

  return url
}

export function fillOutUrlTemplate(template, data) {
  return template
    .split('/')
    .map(name => {
      let part = name

      const isSlotName = name[0] === ':'
      if (isSlotName) {

        name = name.substring(1)

        if (data[name] === undefined) throwUndefinedVariableException(name)

        part = name !== 'apiPath' ? encodeURIComponent(data[name]): data[name]

        delete data[name]

      }
      return part
    })
    .join('/')
}

function getServerBaseUrl() {

  let url = nconf.get(`${process.env.NODE_ENV}Url`)

  url += vueConf.publicPath

  return url
}

function throwUndefinedVariableException(name) {
  throw `missingActionURLParameterError: url parameter "${name}" is required"`
}
