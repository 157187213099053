import JsonDocumentMixin from './JsonDocumentMixin'

export default {
  mixins: [
    JsonDocumentMixin,
  ],
  props: {
    charCodesTriggeringUpdate: {
      type: Array
    },
    fieldConfig: {
      type: Object,
      required: true
    },
    objectPath: {
      type: String,
      required: true
    },
    trimValue: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      originalValue: '',
      complaints: [],
    }
  },
  computed: {
    fieldValue: {
      get() {
        let field

        if (this.object) {
          field = this.object

          field = field[this.fieldConfig.name]
        }

        return field
      },
      set (newValue) {

        this.patchDocument(this.mutatedClone(this.objectPath + '.' + this.fieldConfig.name, newValue))

      }
    }
  },
  mounted() {
    this.originalValue = this.fieldValue
  },
  methods: {
    restoreAttribute() {
      this.fieldValue = this.originalValue
    },
    updateValue(newVal) {
      this.complaints = []

      let checks = this.checks || []

      if (this.fieldConfig.required) {
        checks.push({
          validator: (val) => `${val}`.trim() !== '',
          complaint: 'this field is required and cannot be left empty'
        })
      }

      if (checks) {
        checks.forEach(c => {
          if (c.validator && c.complaint) {
            if (!c.validator(newVal)) {
              this.complaints.push(c.complaint)
            }
          } else {
            const errMessage = `JsonDocFieldMixin.malformedCheckError: checks need a validator function and a complaint string. field name: "${this.fieldConfig.name}"`
            this.complaints.push(errMessage)
          }
        })
      }
      this.fieldValue = newVal
    },
    onKey(event) {
      const abortingKeys = [
        27, // escape
      ]
      const restoreValue = abortingKeys.includes(event.keyCode)
      if (restoreValue) {
        this.restoreAttribute()
      } else {
        const performUpdate = this.charCodesTriggeringUpdate?.includes(event.keyCode) ?? true

        if (performUpdate) {
          this.updateValue(event.target.value)
        }
      }
    }
  }
}
