import Vue from "vue"

export const isDeleteToken = (itemUpdate) => {
  return itemUpdate.id && Object.keys(itemUpdate).length === 1
}
export const getDeleteToken = (id) => [{id}]

export function set(state, path, value) {
  let steps = path
  if (!Array.isArray(path)) steps = path.split('.')

  const lastStep = steps.splice(steps.length -1 , 1)

  let context = state
  steps.forEach(step => {
    context = context[step]
  })

  if (value === undefined) {
    Vue.delete(context, lastStep)
  } else {
    Vue.set(context, lastStep, value)
  }
}

export function upsert(state, collectionName, updates) {

  if (Array.isArray(updates)) {

    updates.forEach(item => {

      if (isDeleteToken(item)) {
        set(state, [collectionName, item.id])
      } else {
        set(state, [collectionName, item.id], item)
      }

    })

  } else {

    const update = updates
    set(state, `${collectionName}.${update.id}`, update)

  }
}
