import { getActionRequestObject } from './getActionRequestObject'

const disableCache =  true

export function getAxiosRequestObject(action, context) {

  let request = getActionRequestObject(action, {...context})

  if (disableCache) {
    request.headers.cacheDefier = Date.now()
  }

  return {
    ...request,
    withCredentials: true // todo: figure this out (http cookie protection?)
  }
}

