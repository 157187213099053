import * as actions from "@/store/graph/types"
import { set, upsert }      from "@/store/graph/mutations/helpers/storeMutations"

export default {

  [actions.CREATE_DOCUMENT_COLLECTION]:   (state, action) => upsert(state, 'collections', action.response ?? []),
  [actions.CREATE_DOCUMENT]:              (state, action) => {
    upsert(state, 'documents', action.response.documents ?? [])
    upsert(state, 'versions', action.response.versions ?? [])
  },
  [actions.CREATE_NEW_DOCUMENT_VERSION]:  (state, action) => upsert(state, 'versions', action.response.versions ?? []),

  [actions.LIST_COLLECTIONS]:             (state, action) => {
    const collections =  action.response.collections
    collections.forEach(collection => set(state, ['collections', collection.name], collection))
  },
  [actions.LIST_LATEST_DOCUMENT_VERSIONS]:    (state, action) => {
    upsert(state, 'documents', action.response.documents ?? [])
    upsert(state, 'versions', action.response.versions ?? [])
  },
  [actions.LIST_DOCUMENT_VERSIONS]:       (state, action) => upsert(state, 'versions', action.response ?? []),

  [actions.GET_DOCUMENT_BY_NAMES]:        (state, action) => {
    if (action.response?.documents || action.response?.versions) {
      upsert(state, 'documents', action.response.documents ?? [])
      upsert(state, 'versions', action.response.versions ?? [])
    }
  },
  [actions.GET_DOCUMENT_VERSION]:         (state, action) => upsert(state, 'versions', action.response ?? []),
  [actions.GET_VERSION]:                  (state, action) => upsert(state, 'versions', action.response ?? []),

  [actions.PATCH_VERSION_CONTENT]:        (state, patch) =>  set(state, ['versions', patch.nodeId, patch.attributeName], patch.newValue),
  [actions.PUT_DOCUMENT_SERIES_SEQUENCE]: (state, action) => upsert(state, 'documents', action.response),

  [actions.DELETE_VERSION]:               (state, action) => set(state, ['versions', action.context.versionId]),
  [actions.DELETE_DOCUMENT]:              (state, action) => set(state, ['documents', action.context.documentId]),
  [actions.DELETE_COLLECTION]:            (state, action) => set(state, ['collections', action.context.collectionName]),

}
