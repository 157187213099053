import graphMutations from './graph/mutations'
import * as actions from './types'
import Vue from 'vue'

const mutations = {
  ...graphMutations,
  [actions.SET_ACTIVE_ROUTE](state, value){
    Vue.set(state, 'activeRoute', value)
  },
  [actions.SET_LANGUAGE](state, value) {
    Vue.set(state, 'language', value)
  },
  [actions.FLASH_ERROR](state, value){
    Vue.set(state, 'error', value)
  },
  [actions.FLASH_WARNING](state, message){
    Vue.set(state, 'warning', message)
  },

}
export default mutations
