export function loadContextVariables(state, context) {
  if (state.user) {
    context = {
      ...context,
      ...state.user,
    }
  }
  context.apiPath = state.apiPath
  return context
}
