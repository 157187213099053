import * as globalActions        from '../../types'
import * as actions              from '../../graph/types'
import { maskSensitiveData }     from './maskSensitiveData'
import { getAxiosRequestObject } from './getAxiosRequestObject'
import { loadContextVariables }  from "./loadContextVariables"
import axios                     from "axios"
import _                         from 'lodash'
import deepCompare               from "../../../lib/compareObjects"
import {isObject}                from '../../../../../server/helpers/types'
import actionTimeouts            from './hashedTimeouts'

function actionExists(action) {
  return Object.keys(actions).findIndex((k) => actions[k] === action) >= 0
}

function areSame(a, b) {
  let same = true
  if (isObject(a) && isObject(b)) {
    same = deepCompare(a, b)
  } else {
    same = a === b
  }
  return same
}

const throttledPushToServer = _.throttle(
  (commit, state, actionRecipe, patch) => {
    const context = loadContextVariables(state, patch)

    const re = getAxiosRequestObject(actionRecipe, context) // todo: lookup dirty state version
    const actionId = JSON.stringify(re)

    if (actionTimeouts.isCool(actionId)) {

      actionTimeouts.addTimeout(actionId)

      return axios(re)
        .then(response => {

          const dbValue = response.data[0][patch.attributeName]
          const isPristine = areSame(
            dbValue,
            state[patch.collectionName][patch.nodeId][patch.attributeName]
          )

          if (isPristine) {

            commit(actions.DELETE_PRISTINE_STATE, patch)

          }

          actionTimeouts.cancelTimeout(actionId)

        })
        .catch(err => {

          console.log('actError. restoring pristine state.', 'action:', actionRecipe, maskSensitiveData(re), err)
          // todo: user feedback.
          //   options?
          commit(globalActions.FLASH_ERROR, err)
          commit(actions.RESTORE_PRISTINE_STATE, patch)

        })

    }
  },
  2000,
  { trailing: true }
)

/**
 * mutates local state immediately for responsive actions
 *
 *  fx. fast edits of text fields
 */
export function act({commit, state}, actionRecipe, patch) {

  if (!actionExists(actionRecipe)) throw `APIRequest.act ERROR unknown action "${actionRecipe}"`

  const reference = [
    patch.collectionName,
    patch.nodeId,
    patch.attributeName
  ].join('.')

  const isPristine = areSame(
    state.pristine[reference],
    state[patch.collectionName][patch.nodeId][patch.attributeName]
  )

  if (isPristine) {
    commit(actions.CREATE_PRISTINE_STATE, patch)
  }

  commit(actionRecipe, patch)

  throttledPushToServer(commit, state, actionRecipe, patch)

}

/**
 * awaits response before it mutates the local state
 *
 *  fx. create new objects, security-related functionality
 */
export function sync({commit, state}, action, payload) {
  if (payload === undefined) payload = {}

  if (!actionExists(action)) throw `APIRequest.sync ERROR unknown action "${action}"`

  const context = loadContextVariables(state, payload)

  const re = getAxiosRequestObject(action, context)
  const actionId = JSON.stringify(re)

  if (actionTimeouts.isCool(actionId)) {

    actionTimeouts.addTimeout(actionId)

    return axios(re)
      .then(result => {

        commit(action, {
          name: action,
          context,
          response: result.data?.[0]
        })

        actionTimeouts.cancelTimeout(actionId)

        return result
      })
      .catch(err => {

        console.log('action error', action, action, maskSensitiveData(re), err)
        commit(globalActions.FLASH_ERROR, err)

        if (payload.commitOnFailure) {
          commit(action, {
            name: action,
            context,
          })
        }

      })
  }
}
