import * as actions from '../../store/graph/types'
export default {
  props: {
    collectionName: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
    }
  },
  data () {
    return {
      aliasDocumentIdMap: {},
    }
  },
  computed: { // todo: revisit mapping computed to methods (will always push changes)
    collection() {
      return this.$store.state.collections[this.collectionName]
    },
    componentName: {
      get() {
        let name = 'unknown'
        if (this.document) {
          name = this.document.componentName
          if (this.documentType === 'md') {
            name = 'vDocument'
          }
        }
        return name
      }
    },
    document: {
      get() {
        let document = this.$store.state.documents[this.documentId]
        return document
      }
    },
    documents: {
      get() {
        let documents = []
        if (this.collectionName) {
          documents = Object.values(this.$store.state.documents)
            .filter(d => d.collectionName === this.collectionName)
            .sort(this.byAscendingIndex)
        }

        return documents
      }
    },
    documentAliases: {
      get() {
        let aliases = ['CollectionMixin.documentAliases Error: missing component prop (Function) "getDocumentAlias"']

        if (this.getDocumentAlias) {
          aliases = this.mapDocumentAliases()
        }
        return aliases
      },
      set(aliasSequence) {
        const documentIdSequence = aliasSequence.map(alias => this.aliasDocumentIdMap[alias])
        this.updateDocumentSequence(documentIdSequence)
      }
    },
    documentType: {
      get () {
        let type
        if (this.document) {
          type = document.type
          if (!document.type) {

            if (this.document.json) type = 'json'
            else if (this.document.md) type = 'md'
            else if (document.csv) type = 'csv'

          }
          if (!type) {

            console.log('collectionName', this.collectionName)
            console.log('documentId', this.documentId)
            console.log('unsupported document type:', this.document)

          }
          return type
        }
      }
    },
    latestDocument() {
      const collection = this.collection ?? []
      let document
      if (collection.length) {
        const docs = Object.values(this.collection)

        let highestIndex = 0
        docs.forEach(doc => {
          if (doc.index > highestIndex) {
            highestIndex = doc.index
            document = doc
          }
        })
      }
      return document
    },
  },
  mounted() {
    this.loadDocuments()
  },
  methods: {
    byAscendingIndex(a, b) {
      return a.index < b.index ? -1 : 1
    },
    byDescendingIndex(a, b) {
      return a.index < b.index ? 1 : -1
    },
    deleteCollectionDocument(documentId) {
      this.$store.dispatch(actions.DELETE_DOCUMENT, {
        collectionName: this.collectionName,
        documentId
      })
    },
    loadDocuments() {
      const collectionNotLoaded = this.collectionName && (!this.collection || !this.collection.length)
      const documentNotLoaded = this.documentId && !this.document
      if (collectionNotLoaded || documentNotLoaded) {
        this.$store.dispatch(actions.LIST_LATEST_DOCUMENT_VERSIONS, {
          collectionName: this.collectionName,
        })
      }
    },
    mapDocumentAliases() {
      this.aliasDocumentIdMap = {}
      this.documents.forEach(d => {
        let alias = this.getDocumentAlias(d)
        const aliasIsAmbiguous = this.aliasDocumentIdMap[alias] !== undefined
        if (aliasIsAmbiguous) {
          alias = d.id
        }
        this.aliasDocumentIdMap[alias] = d.id
      })
      return Object.keys(this.aliasDocumentIdMap)
    },
    updateDocumentSequence(documentIdSequence){
      this.$store.dispatch(actions.PUT_DOCUMENT_SERIES_SEQUENCE, {
        collectionName: this.collectionName,
        documentIdSequence
      })
    },

  }
}
