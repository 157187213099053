import content        from './content'
import collections from './collections'
import users        from './users'

export default {
  ...content,
  ...collections,
  ...users,
  requestedActions: {},
  pristine: {}
}
